import Board from "./Board"
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
            <li>
              <Link to="/Game">Game</Link>
            </li>
          </ul>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
        <Route path="/about">
            <About />
          </Route>
          <Route path="/game">
            <Game />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/test">
            <div> 
              test_route_please_ignore
            </div>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return <h2>Home</h2>;
}
function Game() {
  return <Board></Board>;
}

function About() {
  return (
    <div>
      <div>
      Trump did a fascism.
      </div>
      <img src="/rasputin.jpg" alt="papa raspu" width='395' ></img>

      <div>
      I don't think a person should believe in isms, I think they should believe in themselves.
        -Ferris Bueller
      </div>

    </div>
    
  )
}

function Users() {
  return <h2>Users</h2>;
}
